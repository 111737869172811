import React from 'react';
import { Admin, Login, Resource} from 'react-admin';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import Dashboard from './Dashboard';
import  { GlobalParamList, GlobalParamEdit, GlobalParamCreate} from './pages/globalParams';
import  { TransactionList} from './pages/transactions';
import  { ClientList, ClientEdit, ClientCreate } from './pages/clients';
import  { GameList, GameEdit, GameCreate } from './pages/games';
import  { StaffList, StaffEdit, StaffCreate } from './pages/staff';
import { createMuiTheme } from '@material-ui/core/styles';
import yellow from '@material-ui/core/colors/yellow';
import frenchMessages from 'ra-language-french';

const messages = {
  fr: frenchMessages,
}
const i18nProvider = locale => messages[locale];

const theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: { main: "#eead2c"},
    secondary: { main: "#eead2c"},
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
    
  },
  overrides: {      
    MuiButton: {
        containedPrimary: {
          color: '#000',
          backgroundColor: "#eead2c",   
          marginRight: '5px'
        },
        textPrimary: {
          color: '#000',
          backgroundColor: "#eead2c",   
          marginRight: '5px'
        },  
    },    
    MuiTab: {
      root: {
        color: '#fff!important',
        "&:hover": {
          backgroundColor: "#eead2c",
          color: '#000'
        },
        "&$selected": {
          "backgroundColor": "#eead2c",
          "color": "#000!important"
        }
      }
    }    
  }
});


const SBLoginPage = () => {
  const style = {

    div : {
      width: '100%', 
      height: 200, 
      position: 'absolute',
      display: 'inline-block',
      textAlign: 'center',
      bottom: 0
    },

    img: {
      height: '150px',
      width:'auto',
      verticalAlign: 'middle'

    }
  }
  return (
    <div>
    <div style={style.div}> <img src="/logo.gif" alt='Sport Break' style={style.img}/></div>
    <Login backgroundImage="/bg-login.png" />
      
    </div>
  )
};


const App = () => (
  <Admin theme={theme} locale="fr" i18nProvider={i18nProvider} dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider} loginPage={SBLoginPage}>    
    <Resource  name="clients" list={ClientList} edit={ClientEdit} create={ClientCreate} />
    <Resource  name="transactions" list={TransactionList} />
    <Resource  name="games" list={GameList} edit={GameEdit} create={GameCreate} />
    <Resource  name="staff" list={StaffList} edit={StaffEdit} create={StaffCreate} />    
    <Resource  name="global-params" list={GlobalParamList} edit={GlobalParamEdit} create={GlobalParamCreate} />
  </Admin>
);

export default App;