import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, TextInput, Edit, Create, SimpleForm } from 'react-admin';


    
const StaffTitle = ({ record }) => {
    return <span>Staff {record ? `"${record.name}"` : ''}</span>;
};

const StaffSimpleForm = (props) => (
    <SimpleForm {...props}>    
        <TextInput source="name" label="Nom" />
        <TextInput source="rfid" label="RFID"/>
    </SimpleForm>
);

    
export const StaffList = props => (
    <List perPage={25} {...props}>
        <Datagrid>
            <TextField source="name" label="Nom"/>
            <TextField source="rfid" label="RFID"/>
            <DateField source="createdAt" label="Créé le"/>
            <DateField source="updatedAt" label="Modifié le"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const StaffEdit = props => (
    <Edit title={<StaffTitle/>} {...props}>
            <StaffSimpleForm />
    </Edit>
);

export const StaffCreate = props => (
    <Create {...props} >
        <StaffSimpleForm redirect="list"/>
    </Create>
);
