import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import {
    Button,
    REDUX_FORM_NAME,
    DateTimeInput
} from 'react-admin';

//import {DateTimeInput } from 'react-admin-date-inputs';

import IconContentAdd from '@material-ui/icons/Add';
import IconTime from '@material-ui/icons/AccessTime';

const required = (message = 'Obligatoire') =>
    value => value ? undefined : message;

class CreditButtons extends Component {
    
    

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            unlimited: this.props.credits && this.props.credits.begin_date !== null
        };
    }

    handleClick = (value) => {
        const { change, credits } = this.props;
        if(value === -1) {
            this.setState({unlimited: true})
            change(REDUX_FORM_NAME, 'credits', {balance: 0});
        } else {
            this.setState({unlimited: false})
            const new_credits = {balance: credits?credits.balance+value:value};
            change(REDUX_FORM_NAME, 'credits', new_credits);
        }        
    };

    handleChange = (e) => {
        const { change } = this.props;
        console.log("Handle change", e.target.value)
        change(REDUX_FORM_NAME, 'credits', {end_date: e.target.value});        
    };


    render() {
        const unlimited = this.state.unlimited;
        return (
            <div>
            <Fragment>
                <Button label="25" onClick={() => this.handleClick(25)}><IconContentAdd /></Button><Button label="50" onClick={() => this.handleClick(50)}><IconContentAdd /></Button><Button label="100" onClick={() => this.handleClick(100)}><IconContentAdd /></Button><Button label="Illimité" onClick={() => this.handleClick(-1)}><IconTime /></Button>
            </Fragment>
            {unlimited ? (
                <div>
                <DateTimeInput source="credits.begin_date" label="Date/heure début" validate={required()} onChange={this.handleChange}/>
                <DateTimeInput source="credits.end_date" label="Date/heure fin" validate={required()} />
                </div>
            ): null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    var formValues = getFormValues(REDUX_FORM_NAME)(state)
    return {
        credits: formValues?formValues.credits:0
    };
};

const mapDispatchToProps = {
    change
};

export default connect(mapStateToProps, mapDispatchToProps)(
    CreditButtons
);