import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import Button from '@material-ui/core/Button';
import { Button, showNotification } from 'react-admin';

import IconOpen from '@material-ui/icons/LockOpenOutlined';


class LockerButton extends Component {
    handleClick = (event) => {
        event.stopPropagation()
        const {record, showNotification } = this.props
        //const { fetchJson } = fetchUtils;
        fetch(`${process.env.REACT_APP_LOCKER_URL}/ouvre/${record.locker}`, {
            method: 'GET',
        }).then((response) => {
                if (response.ok) 
                    showNotification('Casier ouvert');
                else
                    showNotification('Erreur d\'ouverture du casier', 'warning')
                
            }).catch((e) => {
                showNotification('Casier ouvert');
            })
    }

    
    render() {
        const {record} = this.props;
        if(record.locker > 0)
            return <Button label={"Casier "+record.locker} onClick={this.handleClick}><IconOpen /></Button>;
        else 
            return null
    
    }
}

LockerButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
})(LockerButton);