import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import SelectPeriod from './components/SelectPeriod';
import { Button } from 'react-admin';
import IconQueue from '@material-ui/icons/Queue';


export default () => (
    <Card>
        <CardHeader title="Bienvenue dans l'administration du Temple  !" />
        <CardContent>On mettra des stats et indicteurs ici, à définir.</CardContent>

        <Card>
            <CardHeader title="Période en cours" />
            <CardContent><SelectPeriod /></CardContent>
        </Card>

        <Card>
            <CardHeader title="Files d'attente" />
            <CardContent><Button label="Gérer" onClick={()=>window.open(process.env.REACT_APP_API_URL+'/bookings/','_blank')}><IconQueue /></Button></CardContent>
        </Card>
        
    </Card>
);