import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import Button from '@material-ui/core/Button';
import { Link, Button, showNotification } from 'react-admin';

import IconHistory from '@material-ui/icons/History';


class HistoryButton extends Component {
   
    
    render() {
        const {record} = this.props;
        return <Link to={`/transactions/?filter=${encodeURIComponent(JSON.stringify({rfid : record.rfid}))}&order=DESC&page=1&perPage=25&sort=createdAt`} onClick={(e) => e.stopPropagation()}>
            <Button><IconHistory /></Button>
        </Link>
             
        
    
    }
}

HistoryButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
})(HistoryButton);