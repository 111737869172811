import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button} from 'react-admin';
import { goBack } from 'react-router-redux';

class BackButton extends Component {
    handleClick = () => {
        this.props.goBack();
    };

    render() {
        return <Button label="Annuler" onClick={this.handleClick}></Button>;
    }
}

export default connect(null, {
    goBack,
})(BackButton);