import React from 'react';
import {Filter, List, Datagrid, TextField, NumberField, DateField, TextInput, downloadCSV } from 'react-admin';

import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import RefundButton from '../components/RefundButton'


const TransactionFilter = (props) => (
    <Filter {...props}>
         <TextInput label="Nom" source="q" alwaysOn />
         <TextInput label="RFID" source="rfid" alwaysOn />
    </Filter>
);



const exporter = Transactions => {
  
    const csv = convertToCSV({
        data: Transactions,
        fields: ['id', 'key', 'type', 'credits', 'rfid', 'createdAt', 'updatedAt' ]
    });
    downloadCSV(csv, 'Transactions'); // download as 'Transactions.csv` file
}



export const TransactionList = props => (
    <List filters={<TransactionFilter />} sort={{ field: 'createdAt', order: 'DESC' }} exporter={exporter} perPage={25} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="key" label="Nom"/>            
            <TextField source="type" label="Type"/>
            <NumberField source="credits" options={{ maximumFractionDigits: 2 }} label="Crédits"/>            
            <TextField source="rfid" label="RFID"/>            
            <DateField source="createdAt" label="Effectué le" showTime/>
            <RefundButton/>
        </Datagrid>
    </List>
);

