// in src/comments/ApproveButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loading, Query, showNotification, fetchUtils, refreshView} from 'react-admin';
import {Select, MenuItem} from '@material-ui/core/'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles';


const styles = {
    root: {
        width: '150px',
    },
};

class SelectPeriod extends Component {
    handleChange = (id, value) => {
        const { refreshView, showNotification } = this.props;
        const updatedRecord = { key:'period', value: value};
        const { fetchJson } = fetchUtils;
        fetchJson(`${process.env.REACT_APP_API_URL}/global-params/${id}`, 
        { 
            method: 'PUT',
            body: JSON.stringify({access_token: localStorage.getItem('token'), ...updatedRecord})
        }).then(() => {
                showNotification('Période mise à jour approved');
                refreshView();
            })
            .catch((e) => {
                showNotification('Erreur de mise à jour de la période', 'warning')
            });        
    }

    render() {

        const { classes } = this.props;
        const payload = {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'key', order: 'ASC' },
            filter : {
                q: 'period'
            }
         };

        return  (<Query type="GET_LIST" resource="global-params" payload={payload}>
        {({ data, loading, error }) => {
            console.log(data);
            if (loading) { return <Loading />; }
            if (error) { return <p>ERROR</p>; }
            return <Select  className={classes.root} value={data[0].value} floatingLabelText="Période" onChange={(event)=>this.handleChange(data[0].id, event.target.value)}>
                        <MenuItem value="normale">Normale</MenuItem> 
                        <MenuItem value="vacances">Vacances</MenuItem> 
                    </Select>
        }}
        </Query> )     
        
    }
}

SelectPeriod.propTypes = {
    classes: PropTypes.object.isRequired,
    refreshView: PropTypes.func,
    showNotification: PropTypes.func,
};

const enhance = compose(
    withStyles(styles),
    connect(null, {
        showNotification,
        refreshView,
    })
);

export default (enhance(SelectPeriod));