import React from 'react';
import {email, Toolbar, SaveButton, DeleteButton, CloneButton, DateInput, NumberInput, NumberField, Filter, List, Datagrid, TextField, DateField, EditButton, TextInput, Edit, Create, SimpleForm, downloadCSV } from 'react-admin';
import CreditButtons from '../components/CreditButtons'
import LockerButton from '../components/LockerButton'
import BackButton from '../components/BackButton'
import HistoryButton from '../components/HistoryButton'

import { withStyles } from '@material-ui/core';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const required = (message = 'Obligatoire') =>
    value => value ? undefined : message;

const validateEmail = email();

const ClientTitle = ({ record }) => {
    return <span>Client {record ? `"${record.firstname} ${record.lastname}"` : ''}</span>;
};


const ClientFilter = (props) => (
    <Filter {...props}>
         <TextInput label="Rechercher" source="q" alwaysOn />
         <TextInput label="RFID" source="rfid" alwaysOn />
    </Filter>
);

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'start',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton />
        <CloneButton />
        <BackButton />
    </Toolbar>
));

const exporter = clients => {
    const clientsForExport = clients.map(client => {
        const {clientForExport, locker} = client
        clientForExport.credits = client.credits.balance; // add a field
        return clientForExport;
    });
    const csv = convertToCSV({
        data: clientsForExport,
        fields: ['id', 'firstname', 'lastname', 'birthday', 'email', 'telephone', 'credits', 'rfid', 'createdAt', 'updatedAt' ]
    });
    downloadCSV(csv, 'clients'); // download as 'clients.csv` file
}

const ClientSimpleForm = (props) => (
    <SimpleForm {...props}>   
    <TextInput source="firstname" label="Prénom" validate={required()}/>
    <TextInput source="lastname" label="Nom" validate={required()}/>
    <DateInput source="birthday" label="Date de naissance" />
    <TextInput source="email" label="Email" validate={validateEmail}/>
    <TextInput source="telephone" label="Téléphone" />            
    <NumberInput source="credits.balance" label="Crédits" />
    <CreditButtons/>
    <TextInput source="rfid" label="RFID"/>
    <NumberInput source="locker" label="Casier" />
    </SimpleForm>
);

export const ClientList = props => (
    <List filters={<ClientFilter />} sort={{ field: 'updatedAt', order: 'DESC' }} exporter={exporter} perPage={25} {...props}>
        <Datagrid  rowClick="edit">
            <TextField source="firstname" label="Prénom"/>            
            <TextField source="lastname" label="Nom"/>            
            <NumberField source="credits.balance" options={{ maximumFractionDigits: 2 }} label="Crédits"/>
            <DateField source="credits.end_date" label="Illimité jusqu'au" showTime/>
            <TextField source="rfid" label="RFID"/>              
            <DateField source="updatedAt" label="Modifié le"/>
            <LockerButton/>
            <HistoryButton/>
            <EditButton/>

        </Datagrid>
    </List>
);

export const ClientEdit = props => (
    <Edit title={<ClientTitle/>} {...props}>        
        <ClientSimpleForm toolbar={<CustomToolbar />}/>        
    </Edit>
);

export const ClientCreate = props => (
    <Create title={<ClientTitle/>}{...props}>        
            <ClientSimpleForm redirect="list"/> 
    </Create>
);
