import React from 'react';
import { List, Datagrid, TextField, Filter, DateField, TextInput, Edit, Create, SimpleForm, EditButton } from 'react-admin';
//import SelectPeriod from '../components/SelectPeriod'

const GlobalParamTitle = ({ record }) => {
    return <span>Paramètre {record ? `"${record.name}"` : ''}</span>;
};


const GlobalParamFilter = (props) => (
    <Filter {...props}>
         <TextInput label="Rechercher" source="q" alwaysOn />

    </Filter>
);
    
export const GlobalParamList = props => (
    <List filters={<GlobalParamFilter />} {...props}>
        <Datagrid>	
            <TextField source="key" label="Paramètres"/>
            <TextField source="value" label="Valeurs"/>
            <DateField source="updatedAt" label="Modifié le"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const GlobalParamEdit = props => (
    <Edit title={<GlobalParamTitle/>} {...props}>
           <SimpleForm {...props}>    
                <TextInput source="key" label="Clé" />
                <TextInput source="value" label="Valeur"/>
            </SimpleForm>
    </Edit>
);

export const GlobalParamCreate = props => (
    <Create {...props} >
        <SimpleForm {...props}>    
                <TextInput source="key" label="Clé" />
                <TextInput source="value" label="Valeur"/>
        </SimpleForm>
    </Create>
);

