import {
    GET_LIST,
    GET_ONE,
    //GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
    switch (type) {
    case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            page: JSON.stringify(page),
            limit: JSON.stringify(perPage),
            sort: order==='DESC'?"-".concat(field):field,
            q: JSON.stringify(params.filter.q),
            rfid: params.filter.rfid,
            access_token: localStorage.getItem('token')
        };
        if(query.q === '{}') delete query.q
        if(query.rfid === '{}') delete query.rfid

        return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case GET_ONE:
        return { url: `${API_URL}/${resource}/${params.id}?${stringify({access_token: localStorage.getItem('token')})}` };
    // case GET_MANY: {
        
    //     const query = {
    //         filter: JSON.stringify({ id: params.ids }),
    //     };
    //     return { url: `${API_URL}/${resource}?${stringify(query)}` };
    // }
    case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
            filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
            access_token: localStorage.getItem('token')
        };
        return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case UPDATE:
        return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: { method: 'PUT', body: JSON.stringify({...params.data, access_token: localStorage.getItem('token')}) },
        };
    case CREATE:
        return {
            url: `${API_URL}/${resource}`,
            options: { method: 'POST', body: JSON.stringify({...params.data, access_token: localStorage.getItem('token')}) },
        };
    case DELETE:
        return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: { method: 'DELETE', body: JSON.stringify({access_token: localStorage.getItem('token')}) },
        };
    default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const { json } = response;
    switch (type) {
    case GET_LIST:
        return {
            data: json.rows.map(x => x),
            total: json.count,
        };
    case CREATE:
        return { data: { ...params.data, id: json.id } };
    default:
        return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    const { fetchJson } = fetchUtils;

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
        return Promise.all(
            params.ids.map(id =>
                fetchJson(`${API_URL}/${resource}/${id}`, {
                    method: 'DELETE',
                    body: JSON.stringify({access_token: localStorage.getItem('token')})
                })
            )
        ).then(responses => ({
            data: responses.map(response => response.json),
        }));
    }

    const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
    

    return fetchJson(url, options)
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};