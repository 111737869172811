import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import Button from '@material-ui/core/Button';
import { Button, showNotification, fetchUtils, refreshView } from 'react-admin';

import IconRefresh from '@material-ui/icons/RefreshOutlined';


class RestartButton extends Component {
    handleClickRestart = () => {
        const {record, showNotification } = this.props;
        const { fetchJson } = fetchUtils;
        fetchJson(`${process.env.REACT_APP_API_URL}/stop/${record.key}`, {
            method: 'GET',
        }).then(() => {
                showNotification('Activité redémarrée');
                refreshView();
            })
            .catch((e) => {
                showNotification('Erreur', 'warning')
            });
    }

    

    render() {
               
        return <Button label="Redémarrer" onClick={this.handleClickRestart}><IconRefresh /></Button>;
        
    }
}

RestartButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
})(RestartButton);