import React from 'react';
import { List, Datagrid, TextField, Edit, TabbedForm, TextInput, BooleanInput, FormTab, EditButton, Create} from 'react-admin';
import RestartButton from '../components/RestartButton'

const GameTitle = ({ record }) => {
    return <span>Activité {record ? `"${record.name}"` : ''}</span>;
};


const GameForm = (props) => (
    <TabbedForm  {...props}>     
         <FormTab label="Description">
                <TextInput source="key" label="Id"/>
                <TextInput source="name" label="Nom"/>
            </FormTab>
            <FormTab label="Paramètres">
                <TextInput source="parameters.game_duration_int" label="Durée activité (entier)"/>
                <TextInput source="parameters.game_duration_unit" label="Durée activité (unité)"/>
                <TextInput source="parameters.ip_rasp" label="IP Raspberry"/>
                <TextInput source="parameters.max_players" label="Nombre joueurs max" />
                <TextInput source="parameters.max_time" label="Temps de jeu" />
                <TextInput source="parameters.timer_before" label="Timer avant début" />
                <BooleanInput source="parameters.direct_start" label="Démarrage immédiat" />
                
            </FormTab>
            <FormTab label="Prix">
                <TextInput source="prices.price_week" label="Prix semaine"/>                
                <TextInput source="prices.price_weekend" label="Prix WE"/>
                <TextInput source="prices.price_holiday" label="Prix vacances"/>
            </FormTab>
    </TabbedForm>
);

export const GameList = props => (
    <List perPage={25} {...props}>
        <Datagrid>
            <TextField source="key" label="Id"/>
            <TextField source="name" label="Nom"/>
            <RestartButton/>
            <EditButton/>
        </Datagrid>
    </List>
);  

export const GameEdit = props => (
    <Edit  title={<GameTitle/>} {...props}>
            <GameForm />
    </Edit>
);

export const GameCreate = props => (
    <Create {...props}>
            <GameForm />
    </Create>
);