import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import Button from '@material-ui/core/Button';
import { Button, showNotification, fetchUtils, refreshView } from 'react-admin';

import IconAdd from '@material-ui/icons/AddCircleOutline';
import IconRemove from '@material-ui/icons/RemoveCircleOutline';

class RefundButton extends Component {
    handleClickRefund = () => {
        const { refreshView, record, showNotification } = this.props;
        const updatedRecord = { txid: record.id, rfid: record.rfid, key: record.key, refund: Math.abs(record.credits) };
        const { fetchJson } = fetchUtils;
        fetchJson(`${process.env.REACT_APP_API_URL}/clients/refund`, {
            method: 'PUT',
            body: JSON.stringify({access_token: localStorage.getItem('token'), ...updatedRecord})
        }).then(() => {
                showNotification('Client remboursé');
                refreshView();
            })
            .catch((e) => {
                showNotification('Erreur: remboursement échoué', 'warning')
            });
    }

    handleClickDebit = () => {
        const { refreshView, record, showNotification } = this.props;
        const updatedRecord = { txid: record.id, rfid: record.rfid, key: record.key, debit: -Math.abs(record.credits) };
        const { fetchJson } = fetchUtils;
        fetchJson(`${process.env.REACT_APP_API_URL}/clients/debit`, {
            method: 'PUT',
            body: JSON.stringify({access_token: localStorage.getItem('token'), ...updatedRecord})
        }).then(() => {
                showNotification('Client débité');
                refreshView();
            })
            .catch((e) => {
                showNotification('Erreur: débitement échoué', 'warning')
            });
    }

    render() {
        const {record} = this.props;
        if(record.reversed)
            return null
        if(record.credits < 0)
            return <Button label="Rembourser" onClick={this.handleClickRefund}><IconAdd /></Button>;
        else 
            return <Button label="Débiter" onClick={this.handleClickDebit}><IconRemove /></Button>;
    }
}

RefundButton.propTypes = {
    refreshView: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
    refreshView,
})(RefundButton);